import React from "react";
import PropTypes from "prop-types";
import ConnectedChip from "./ConnectedChip";
import SecondaryButton from "../reusable_components/Buttons/SecondaryButton";
import SubsectionDropdownHeader from "../reusable_components/SubsectionDropdownHeader";

const IntegrationCard = ({
  logoSrc,
  headerText,
  captionText,
  onButtonClick,
  isIntegrated,
  buttonIconSrc,
  buttonText,
  showCount = false,
  count = null,
  connectedChipDropdownOptions,
  connectedChipText = "Connected",
  onConnectedChipClick = null,
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "360px",
        height: "208px",
        padding: "20px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        flexShrink: 0,
        borderRadius: "8px",
        background: "var(--white, #FFF)",
        boxShadow: "0px 2px 8px 0px rgba(114, 127, 149, 0.25)",
      }}
    >
      <div
        id="integration-logo-and-header"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          alignSelf: "stretch",
        }}
      >
        <img
          src={logoSrc}
          alt="Integration Logo"
          style={{ width: "24px", height: "24px" }}
        />
        <div
          id="integration-header"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {/* <div
            style={{
              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
              fontFamily: '"Roboto Mono"',
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            {headerText}
          </div> */}
          <SubsectionDropdownHeader
            showDropdownArrow={false}
            showCount={showCount}
            count={count}
            sectionHeader={headerText}
            isExpanded={false}
          />
        </div>
      </div>
      {isIntegrated && (
        <div
          id="integration-caption"
          style={{
            flex: "1 0 0",
            alignSelf: "stretch",
            color: "var(--blue-darkest, #001331)",
            fontFamily: "Rubik",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "145%",
          }}
        >
          {captionText}
          <ConnectedChip
            dropdownOptions={connectedChipDropdownOptions}
            chipText={connectedChipText}
            onClick={onConnectedChipClick}
          />
        </div>
      )}
      {!isIntegrated && (
        <>
          <div
            id="integration-caption"
            style={{
              flex: "1 0 0",
              alignSelf: "stretch",
              color: "var(--blue-darkest, #001331)",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "145%",
            }}
          >
            {captionText}
          </div>
          <SecondaryButton 
            onClick={onButtonClick}
            icon={buttonIconSrc || logoSrc}
          >
            {buttonText}
          </SecondaryButton>
        </>
      )}
    </div>
  );
};

IntegrationCard.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  captionText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  isIntegrated: PropTypes.bool.isRequired,
  buttonIconSrc: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  showCount: PropTypes.bool,
  count: PropTypes.number,
  connectedChipDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  connectedChipText: PropTypes.string,
};

export default IntegrationCard;