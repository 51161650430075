import React, { useEffect, useState } from "react";
import API from "../../../../services/api";
import { Grid, Typography, Snackbar } from "@mui/material";
import SteamIntegrationGameBox from "./SteamTabContentGameBox";

function SteamIntegrationSelectedGames() {
  const [selectedGames, setSelectedGames] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchSelectedGames();
  }, []);

  const fetchSelectedGames = async () => {
    try {
      const response = await API.get("steam/selected-games");
      setSelectedGames(response.data.games);
    } catch (error) {
      console.error("Error fetching selected games:", error);
    }
  };

  const handleSelectChange = async (gameId, isSelected) => {
    try {
      if (!isSelected) {
        await API.post("steam/remove-game", { game_id: gameId });
        fetchSelectedGames();
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating game selection:", error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      {selectedGames.length > 0 ? (
        <Grid container spacing={2}>
          {selectedGames.map((game) => (
            <Grid item key={game.id}>
              <SteamIntegrationGameBox
                game={game}
                isSelected={true}
                onSelectChange={handleSelectChange}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6">
          No selected games. Go to the search tab to add games!
        </Typography>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Game and associated reviews removed"
        sx={{
          bottom: "80px",
          "& .MuiSnackbarContent-root": {
            width: "auto",
            minWidth: "200px",
            maxWidth: "800px",
            fontSize: "1.1rem",
            padding: "12px 24px",
            textAlign: "center",
            display: "block",
          },
        }}
      />
    </div>
  );
}

export default SteamIntegrationSelectedGames;
