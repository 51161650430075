import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

const ConnectedChip = ({ style, chipText = "Connected", onClick, dropdownOptions }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChipClick = () => {
    if (dropdownOptions) {
      setIsDropdownOpen(!isDropdownOpen);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "flex-end", marginTop: "36px" }}
    >
      <div ref={dropdownRef} style={{ position: "relative" }}>
        <div
          onClick={handleChipClick}
          style={{
            display: "flex",
            padding: "4px 8px 4px 12px",
            alignItems: "center",
            gap: "8px",
            borderRadius: "8px",
            background: "var(--color-green-light, #DEF8F0)",
            cursor: "pointer",
            ...style,
          }}
        >
          <div
            id="CheckMarkAndConnectedText"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ width: "20px", height: "20px" }}
            >
              <path
                d="M8.81727 13.5447L14.4198 7.94221L13.5416 7.06409L8.81727 11.7885L6.44227 9.41346L5.56415 10.2916L8.81727 13.5447ZM10.0014 17.9166C8.90644 17.9166 7.8772 17.7088 6.91373 17.2933C5.95026 16.8777 5.1122 16.3137 4.39956 15.6014C3.68692 14.889 3.12269 14.0513 2.70685 13.0883C2.29116 12.1252 2.08331 11.0962 2.08331 10.0014C2.08331 8.90638 2.29109 7.87714 2.70665 6.91367C3.1222 5.9502 3.68616 5.11214 4.39852 4.3995C5.11088 3.68686 5.94859 3.12263 6.91165 2.70679C7.8747 2.2911 8.90366 2.08325 9.99852 2.08325C11.0935 2.08325 12.1228 2.29103 13.0862 2.70658C14.0497 3.12214 14.8878 3.6861 15.6004 4.39846C16.313 5.11082 16.8773 5.94853 17.2931 6.91159C17.7088 7.87464 17.9166 8.9036 17.9166 9.99846C17.9166 11.0935 17.7089 12.1227 17.2933 13.0862C16.8778 14.0496 16.3138 14.8877 15.6014 15.6003C14.8891 16.313 14.0514 16.8772 13.0883 17.293C12.1253 17.7087 11.0963 17.9166 10.0014 17.9166ZM9.99998 16.6666C11.8611 16.6666 13.4375 16.0208 14.7291 14.7291C16.0208 13.4374 16.6666 11.861 16.6666 9.99992C16.6666 8.13881 16.0208 6.56242 14.7291 5.27075C13.4375 3.97909 11.8611 3.33325 9.99998 3.33325C8.13887 3.33325 6.56248 3.97909 5.27081 5.27075C3.97915 6.56242 3.33331 8.13881 3.33331 9.99992C3.33331 11.861 3.97915 13.4374 5.27081 14.7291C6.56248 16.0208 8.13887 16.6666 9.99998 16.6666Z"
                fill="#0AA476"
              />
            </svg>
            <span
              style={{
                color: "var(--slate-dark, #727F95)",
                fontFamily: '"Roboto Mono"',
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%",
              }}
            >
              {chipText}
            </span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.55375 19.5L4.5 18.4462L15.9462 7H9V5.5H18.5V15H17V8.05375L5.55375 19.5Z"
              fill="#0AA476"
            />
          </svg>
        </div>
        {isDropdownOpen && dropdownOptions && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              right: 0,
              backgroundColor: "white",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
              borderRadius: "4px",
              zIndex: 1000,
            }}
          >
            {dropdownOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => {
                  option.onClick();
                  setIsDropdownOpen(false);
                }}
                style={{
                  padding: "8px 16px",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  transition: "background-color 0.2s ease",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#f0f0f0";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ConnectedChip.propTypes = {
  style: PropTypes.object,
  chipText: PropTypes.string,
  onClick: PropTypes.func,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default ConnectedChip;
