import React, { useState, useEffect } from "react";
import AnchorMenuForIntegrationsTabs from "./AnchorMenuForIntegrationsTabs";
import { useIntegrations } from "../../contexts/IntegrationsContext";
import { useAuth } from "../../contexts/AuthContext";
import AllIntegrationsTabContent from "./DataIntegrationsPageContentForEachTab/AllIntegrationsTabContent";
import JiraTabContent from "./DataIntegrationsPageContentForEachTab/JiraTabContent";
import LocalDocumentsTabContent from "./DataIntegrationsPageContentForEachTab/LocalDocumentsTabContent";
import SteamTabContent from "./DataIntegrationsPageContentForEachTab/SteamIntegration/SteamTabContent"; // Add this import
import SlackTabContent from "./DataIntegrationsPageContentForEachTab/SlackIntegration/SlackTabContent"; // Add this import

import IntegrationCard from "./IntegrationCard";
import DocIcon from "../../assets/icons/DocIcon.svg";
import UploadFileIcon from "../../assets/icons/UploadFileIcon.svg";
import { initiateJiraOAuth } from "./integrationOAuthHelpers";

const DataIntegrationsPage = () => {
  const [selectedTab, setSelectedTab] = useState("All Integrations");
  const { numLocalFilesUploaded, integrations, refreshIntegrations } = useIntegrations();
  const { user } = useAuth();

  // Set up interval to refresh integrations every 15 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshIntegrations();
    }, 15000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [refreshIntegrations]);

  const tabs = [
    { text: "All Integrations", showCheckMarkIcon: false, showChipText: false },
  ];

  if (numLocalFilesUploaded > 0) {
    tabs.push({
      text: "Local Documents",
      showChipText: true,
      chipText: numLocalFilesUploaded.toString(),
    });
  }

  if (integrations.jira) {
    tabs.push({ text: "Jira", showCheckMarkIcon: true, showChipText: false });
  }
  if (integrations.steam) {
    tabs.push({ text: "Steam", showCheckMarkIcon: true, showChipText: false });
  }
  if (integrations.slack) {
    tabs.push({ text: "Slack", showCheckMarkIcon: true, showChipText: false });
  }

  // Add this useEffect to handle tab deletion
  useEffect(() => {
    const isSelectedTabAvailable = tabs.some(tab => tab.text === selectedTab);
    if (!isSelectedTabAvailable) {
      setSelectedTab("All Integrations");
    }
  }, [tabs, selectedTab]);

  const handleTabClick = (tabText) => {
    setSelectedTab(tabText);
  };

  const handleJiraIntegration = () => {
    initiateJiraOAuth(user);
  };

  const handleTabChange = (tabText) => {
    setSelectedTab(tabText);
  };

  const renderTabContent = () => {
    const content = (() => {
      switch (selectedTab) {
        case "All Integrations":
          return <AllIntegrationsTabContent onTabChange={handleTabChange} />;
        case "Jira":
          return <JiraTabContent />;
        case "Local Documents":
          return <LocalDocumentsTabContent />;
        case "Steam":
          return <SteamTabContent />;
        case "Slack":
          return <SlackTabContent />; // Add this case
        default:
          return null;
      }
    })();

    return <div style={{ width: '100%' }}>{content}</div>;
  };

  return (
    <div
      className="data-integrations-page-content"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
      }}
    >
      <div
        id="page-title"
        style={{
          alignSelf: "stretch",
          color: "var(--blue-darkest, var(--blue-darkest, #001331))",
          fontFamily: '"Roboto Mono"',
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "135%",
        }}
      >
        Integrations
      </div>
      <AnchorMenuForIntegrationsTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabClick={handleTabClick}
      />
      {renderTabContent()}
    </div>
  );
};

export default DataIntegrationsPage;
