import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./styles/global.css";
import DataIntegrationsPage from "./components/DataIntegrationsPage/DataIntegrationsPage";
import JiraSyncUserFilterSelectionPage from "./components/DataIntegrationsPage/OLD/jiraSyncUserFilterSelectionPage";
import ClassifyFilesPage from "./components/ClassifyFilesPage/ClassifyFilesPage";
import MetricCalculationsPage from "./components/MetricCalculationsPage/MetricCalculationsPage";
import Layout from "./components/Layout";
import LoginForm from "./components/auth/LoginForm";
import RegistrationForm from "./components/auth/RegistrationForm";
import ForgotPasswordForm from "./components/auth/ForgotPasswordForm";
import ResetPasswordForm from "./components/auth/ResetPasswordForm";
import { PrivateRoute } from "./routes/PrivateRoute";
import { AuthenticatedRoute } from "./routes/AuthenticatedRoute";
import { AuthProvider } from "./contexts/AuthContext";
import InitiativesPage from "./components/InitiativesPage/InitiativesPage/InitiativesPage";
import MyAccountPage from "./components/MyAccountPage/MyAccountPage";
import InsightsPage from "./components/InsightsPage/InsightsPage";
import DevelopmentTimePage from "./components/MetricCalculationsPage/MetricCalculationsPagesForEachMetricType/DevelopmentTime/DevelopmentTimeCalculations";
import XProdFeedback from "./components/XProdFeedback/XProdFeedback";
import { StatusProvider } from "./contexts/StatusContext";
import StatusBox from "./components/StatusBox/StatusBox";
import PaymentSuccess from "./components/auth/PaymentSuccess";
import PaymentCancel from "./components/auth/PaymentCancel";
import PaymentForm from "./components/auth/PaymentForm";
import { PreAuthRoute } from "./routes/PreAuthRoute";
import RenewSubscription from "./components/auth/RenewSubscription";
import AboutInitiativePage from "./components/InitiativesPage/AboutInitiativePage/AboutInitiativePage";
import { PaymentProvider } from "./contexts/PaymentContext";
import SingleAggregatePainPointPage from "./components/PagesToDisplayASingleAggregateInsightForEachType/SingleAggregatePainPointPage/SingleAggregatePainPointPage";
import SingleAggregateFeatureRequestPage from "./components/PagesToDisplayASingleAggregateInsightForEachType/SingleAggregateFeatureRequestPage/SingleAggregateFeatureRequestPage";
import SingleObjectivePage from "./components/PagesToDisplayASingleAggregateInsightForEachType/SingleObjectivePage/SingleObjectivePage";
// import SingleAggregateQuantitativeStatementPage from "./components/PagesToDisplayASingleAggregateInsightForEachType/SingleAggregateQuantitativeStatementPage/SingleAggregateQuantitativeStatementPage";
import TeamInformationPage from "./components/TeamInformationPage";
import { InitiativesProvider } from "./contexts/InitiativesPageContext";
import { IntegrationsProvider } from "./contexts/IntegrationsContext";
import DocViewerPage from "./components/DocViewerPage/DocViewerPage";
import { OnboardingCheckRoute } from "./routes/OnboardingCheckRoute";
import OnboardingFlow from "./components/OnboardingFlow/OnboardingFlow";
import CsvViewer from "./components/reusable_components/csvViewer/csvViewer";
import SteamIntegrationPage from "./components/DataIntegrationsPage/DataIntegrationsPageContentForEachTab/SteamIntegration/SteamTabContent";
import { useLocation } from 'react-router-dom';
import API from './services/api';
import AuthService from './components/auth/AuthService';
import SlackOauthCallback from "./components/DataIntegrationsPage/DataIntegrationsPageContentForEachTab/SlackIntegration/SlackOauthCallback";

function RouteChangeLogger() {
  const location = useLocation();

  useEffect(() => {
    const logPageView = async () => {
    const user = AuthService.getCurrentUser();
    if (user && process.env.REACT_APP_IS_PRODUCTION === 'true') {
        try {
          await API.post("/analytics/page-view", { path: location.pathname });
        } catch (error) {
          // console.error('Failed to log page view:', error);
        }
      }
    };

    logPageView();
  }, [location]);

  return null;
}

function App() {
  return (
    <AuthProvider>
      <PaymentProvider>
        <Router>
          <RouteChangeLogger />
          <Layout>
            <StatusProvider>
              <InitiativesProvider>
                <Routes>
                  {/* Add this new Route at the beginning of the Routes */}
                  <Route
                    path="/"
                    element={<Navigate to="/initiatives" replace />}
                  />

                  <Route element={<AuthenticatedRoute />}>
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/register" element={<RegistrationForm />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordForm />}
                    />
                    <Route
                      path="/reset-password/:token"
                      element={<ResetPasswordForm />}
                    />
                  </Route>
                  <Route element={<PreAuthRoute />}>
                    <Route path="/payment" element={<PaymentForm />} />
                    <Route
                      path="/payment-success"
                      element={<PaymentSuccess />}
                    />
                    <Route path="/payment-cancel" element={<PaymentCancel />} />
                    <Route
                      path="/renew-subscription"
                      element={<RenewSubscription />}
                    />
                  </Route>
                  <Route element={<PrivateRoute />}>
                    <Route path="/onboarding" element={<OnboardingFlow />} />
                    <Route element={<OnboardingCheckRoute />}>
                      <Route
                        element={
                          <IntegrationsProvider>
                            <Outlet />
                          </IntegrationsProvider>
                        }
                      >
                        <Route
                          path="/initiatives"
                          element={<InitiativesPage />}
                        />
                        <Route
                          path="/development-time"
                          element={<DevelopmentTimePage />}
                        />
                        <Route
                          path="/data-integrations"
                          element={<DataIntegrationsPage />}
                        />
                        <Route
                          path="/jira-sync-filter-selection"
                          element={<JiraSyncUserFilterSelectionPage />}
                        />
                        <Route
                          path="/classify-files"
                          element={<ClassifyFilesPage />}
                        />
                        <Route path="/insights" element={<InsightsPage />} />
                        <Route
                          path="/insights/aggregate-pain-point/:id"
                          element={<SingleAggregatePainPointPage />}
                        />
                        <Route
                          path="/insights/individual-pain-point/:id"
                          element={<SingleAggregatePainPointPage />}
                        />
                        <Route path="/my-account" element={<MyAccountPage />} />
                        <Route path="/feedback" element={<XProdFeedback />} />
                        <Route
                          path="/initiatives/:initiativeId/metrics/:metricType"
                          element={<MetricCalculationsPage />}
                        />
                        <Route
                          path="/initiatives/:initiativeId/about"
                          element={<AboutInitiativePage />}
                        />
                        <Route
                          path="/insights/aggregate-feature-request/:id"
                          element={<SingleAggregateFeatureRequestPage />}
                        />
                        <Route
                          path="/insights/individual-feature-request/:id"
                          element={<SingleAggregateFeatureRequestPage />}
                        />
                        <Route
                          path="/insights/objective/:objectiveId"
                          element={<SingleObjectivePage />}
                        />
                        <Route
                          path="/team-information"
                          element={<TeamInformationPage />}
                        />
                        <Route
                          path="/doc-viewer/:fileMetadataDocMongoId/:initialMentionIdToScrollTo?"
                          element={<DocViewerPage />}
                        />
                        <Route path="/csv-viewer" element={<CsvViewer />} />
                        <Route
                          path="/data-integrations/steam-integration"
                          element={<SteamIntegrationPage />}
                        />
                        <Route
                          path="/integrations/slack-oauth-callback"
                          element={<SlackOauthCallback />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Routes>
              </InitiativesProvider>
              <StatusBox />
            </StatusProvider>
          </Layout>
        </Router>
      </PaymentProvider>
    </AuthProvider>
  );
}

export default App;
