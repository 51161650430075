import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import { MdMoreVert } from "react-icons/md";
import API from "../../services/api";
import { StatusContext } from "../../contexts/StatusContext";
import StatusBox from "../StatusBox/StatusBox";
import AggregatePainPointCard from "../reusable_components/InsightsCards/AggregatePainPointCard";
import AggregateFeatureRequestCard from "../reusable_components/InsightsCards/AggregateFeatureRequestCard";
import ObjectiveCard from "../reusable_components/InsightsCards/ObjectiveCard";
import PageLoadSpinner from "../reusable_components/PageLoadSpinner/PageLoadSpinner";
import { useNavigate } from "react-router-dom";

const InsightsPage = () => {
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);
   const [painPoints, setPainPoints] = useState([]);
   const [featureRequests, setFeatureRequests] = useState([]);
   const [objectives, setObjectives] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
   const [filesWithDocumentTypeCount, setFilesWithDocumentTypeCount] = useState(0);
   const navigate = useNavigate();

   useEffect(() => {
      fetchInsights();
   }, []);

   const fetchInsights = async () => {
      try {
         setIsLoading(true);
         const [aggregateResponse, kgInsightsResponse, filesWithDocumentTypeCountResponse] = await Promise.all([
            API.get("/insights/get-aggregate-insights-for-user"),
            API.get("/insights/get-kg-insights-for-user"),
            API.get("/file-management/get-files-with-document-type-count"),
         ]);

         const filteredPainPoints = (aggregateResponse.data.aggregate_pain_point || []).filter(
            (painPoint) => painPoint.percentage > 0.05
         );
         const filteredFeatureRequests = (aggregateResponse.data.aggregate_feature_request || []).filter(
            (featureRequest) => featureRequest.percentage > 0.05
         );
         
         setPainPoints(filteredPainPoints);
         setFeatureRequests(filteredFeatureRequests);
         setObjectives(kgInsightsResponse.data.objective_kg_node || []);
         setFilesWithDocumentTypeCount(filesWithDocumentTypeCountResponse.data.count);
      } catch (error) {
         console.error("Error fetching insights:", error);
      } finally {
         setIsLoading(false);
      }
   };

   const renderStatItem = (count, label, color) => (
      <div className="flex gap-3 justify-center items-center pr-4 bg-white rounded-lg border border-solid border-slate-300">
         <div 
            className={`shrink-0 w-1.5 rounded-l-lg ${color}`}
            style={{ height: '47px' }}
         />
         <div className="flex gap-1.5 items-center self-stretch my-auto">
            <div className="self-stretch my-auto text-xl text-slate-900">{count}</div>
            <div className="self-stretch my-auto text-sm tracking-wide uppercase text-slate-500">
               {label}
            </div>
         </div>
      </div>
   );

   return (
      <div>
         {isLoading ? (
            <PageLoadSpinner />
         ) : (
            <>
               <div className="flex justify-between items-center mb-6">
                  <h1 className="text-[#001331] font-['Roboto_Mono'] text-2xl font-medium leading-[135%]">
                     Insights
                  </h1>
               </div>

               {/* Stats section moved here */}
               <div className="flex flex-wrap gap-10 items-center pb-6 mb-6 leading-none">
                  <div className="flex flex-wrap flex-1 shrink gap-6 items-start self-stretch my-auto font-medium basis-0 min-w-[240px] max-md:max-w-full">
                     {renderStatItem(painPoints.length, "Pain Points", "bg-[#F26969]")}
                     {renderStatItem(featureRequests.length, "Feature Requests", "bg-[#628FDA]")}
                     {renderStatItem(objectives.length, "Objectives", "bg-[#0AA476]")}
                  </div>
                  <button 
                     className="flex gap-3 items-center self-stretch my-auto text-base font-bold tracking-wide text-black capitalize min-w-[240px] cursor-pointer"
                     onClick={() => navigate("/classify-files")}
                  >
                     <div className="self-stretch my-auto">Extracted from {filesWithDocumentTypeCount} documents</div>
                     <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/61bde006424a8fc1e7637dc92415c8dfd4fa1bc791e86e5ea610729f0d660d2a?placeholderIfAbsent=true&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43"
                        className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
                        alt="Document icon"
                     />
                  </button>
               </div>

               <p className="text-[#727F95] font-['Roboto_Mono'] text-base italic font-normal leading-[140%] mb-8">
                  Click on an insight to view details
               </p>

               <StatusBox />

               <div className="mt-8">
                  <h3 className="flex items-center text-[#001331] font-['Roboto_Mono'] text-xl font-medium leading-[135%] mb-6">
                     <span>Pain Points</span>
                     <span className="ml-2 text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%]">({painPoints.length})</span>
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-2">
                     {painPoints.length > 0 ? (
                        painPoints
                           .sort((a, b) => b.percentage - a.percentage)
                           .map((painPoint) => <AggregatePainPointCard key={painPoint.id} painPoint={painPoint} />)
                     ) : (
                        <p>No pain points available.</p>
                     )}
                  </div>
               </div>

               <div className="mt-8">
                  <h3 className="flex items-center text-[#001331] font-['Roboto_Mono'] text-xl font-medium leading-[135%] mt-20 mb-6">
                     <span>Feature Requests</span>
                     <span className="ml-2 text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%]">({featureRequests.length})</span>
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-2">
                     {featureRequests.length > 0 ? (
                        featureRequests
                           .sort((a, b) => b.percentage - a.percentage)
                           .map((featureRequest) => (
                              <AggregateFeatureRequestCard key={featureRequest.id} featureRequest={featureRequest} />
                           ))
                     ) : (
                        <p>No feature requests available.</p>
                     )}
                  </div>
               </div>

               <div className="mt-8">
                  <h3 className="flex items-center text-[#001331] font-['Roboto_Mono'] text-xl font-medium leading-[135%] mt-20 mb-6">
                     <span>Objectives</span>
                     <span className="ml-2 text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%]">({objectives.length})</span>
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-2">
                     {objectives.length > 0 ? (
                        objectives.map((objective) => <ObjectiveCard key={objective.id} objective={objective} />)
                     ) : (
                        <p>No objectives available.</p>
                     )}
                  </div>
               </div>
            </>
         )}
      </div>
   );
};

export default InsightsPage;