import React, { useState, useEffect, useCallback, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../../services/api";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../reusable_components/LoadingSpinner/LoadingSpinner";
import { addDays, subDays } from "date-fns";
import { StatusContext } from "../../../contexts/StatusContext";

const ProjectSpecificDetails = ({
  filterData,
  workspaces,
  workspaceId,
  projectId,
  onFilterChange,
}) => {
  const [globalData, setGlobalData] = useState({});
  const [projectSpecificData, setProjectSpecificData] = useState({});

  useEffect(() => {
    setGlobalData({
      statuses: filterData.statuses[workspaceId].global,
      priorities: filterData.priorities[workspaceId].global,
      issue_types: filterData.issue_types[workspaceId].global,
    });
    setProjectSpecificData({
      statuses: filterData.statuses[workspaceId].project_specific[projectId],
      priorities:
        filterData.priorities[workspaceId].project_specific[projectId],
      issue_types:
        filterData.issue_types[workspaceId].project_specific[projectId],
    });
  }, [filterData, workspaceId, projectId]);

  const isWorkspaceChecked = useCallback((data, wsId) => {
    return Object.entries(data).some(([category, categoryData]) => {
      if (category === "projects") {
        return (
          categoryData[wsId] &&
          categoryData[wsId].values.some((project) => project.match)
        );
      }
      return (
        categoryData[wsId] &&
        ((categoryData[wsId].global && categoryData[wsId].global.match) ||
          (categoryData[wsId].project_specific &&
            Object.values(categoryData[wsId].project_specific).some(
              (project) => project.match,
            )))
      );
    });
  }, []);

  const handleCheckboxChange = useCallback(
    (section, key, itemName) => {
      const updatedFilterData = JSON.parse(JSON.stringify(filterData));
      const updatedWorkspaces = JSON.parse(JSON.stringify(workspaces));
      const targetData =
        section === "global"
          ? updatedFilterData[key][workspaceId].global
          : updatedFilterData[key][workspaceId].project_specific[projectId];
      const item = targetData.values.find((item) => item.name === itemName);
      item.match = !item.match;

      const anyItemChecked = targetData.values.some((item) => item.match);
      targetData.match = anyItemChecked;

      const workspaceIndex = updatedWorkspaces.findIndex(
        (w) => w.id === workspaceId,
      );
      if (workspaceIndex !== -1) {
        updatedWorkspaces[workspaceIndex].match = isWorkspaceChecked(
          updatedFilterData,
          workspaceId,
        );
      }

      onFilterChange(updatedFilterData, updatedWorkspaces);
    },
    [
      filterData,
      workspaces,
      workspaceId,
      projectId,
      isWorkspaceChecked,
      onFilterChange,
    ],
  );

  const handleSectionChange = useCallback(
    (section, key) => {
      const updatedFilterData = JSON.parse(JSON.stringify(filterData));
      const updatedWorkspaces = JSON.parse(JSON.stringify(workspaces));
      const targetData =
        section === "global"
          ? updatedFilterData[key][workspaceId].global
          : updatedFilterData[key][workspaceId].project_specific[projectId];
      const newMatch = !targetData.match;

      targetData.match = newMatch;
      targetData.values.forEach((item) => {
        item.match = newMatch;
      });

      const workspaceIndex = updatedWorkspaces.findIndex(
        (w) => w.id === workspaceId,
      );
      if (workspaceIndex !== -1) {
        updatedWorkspaces[workspaceIndex].match = isWorkspaceChecked(
          updatedFilterData,
          workspaceId,
        );
      }

      onFilterChange(updatedFilterData, updatedWorkspaces);
    },
    [
      filterData,
      workspaces,
      workspaceId,
      projectId,
      isWorkspaceChecked,
      onFilterChange,
    ],
  );

  const renderDataSection = useCallback(
    (title, data, section) => (
      <>
        <h3 className="text-xl font-semibold mt-8 mb-4">{title}</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {Object.entries(data).map(
            ([key, data]) =>
              data && (
                <div key={key} className="bg-gray-50 p-4 rounded-md">
                  <div className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={`${section}-${key}`}
                      checked={data.match}
                      onChange={() => handleSectionChange(section, key)}
                      className="mr-2"
                    />
                    <h4 className="text-lg font-medium capitalize">
                      {key.replace("_", " ")}
                    </h4>
                  </div>
                  <ul className="list-none">
                    {data.values.map((item) => (
                      <li
                        key={item.name}
                        className="text-sm flex items-center mb-2"
                      >
                        <input
                          type="checkbox"
                          id={`${section}-${key}-${item.name}`}
                          checked={item.match}
                          onChange={() =>
                            handleCheckboxChange(section, key, item.name)
                          }
                          className="mr-2"
                        />
                        <label htmlFor={`${section}-${key}-${item.name}`}>
                          {item.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ),
          )}
        </div>
      </>
    ),
    [handleSectionChange, handleCheckboxChange],
  );

  return (
    <div className="mt-6 bg-white shadow-md rounded-lg p-6">
      {projectId === null
        ? // Render global data when projectId is null (workspace level)
          renderDataSection("Global Data", globalData, "global")
        : // Render project-specific data when projectId is provided
          renderDataSection(
            "Project Specific Data",
            projectSpecificData,
            "project",
          )}
    </div>
  );
};

const JiraSyncUserFilterSelectionPage = () => {
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } =
    useContext(StatusContext);
  const [filterData, setFilterData] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [processId, setProcessId] = useState(null);

  const handleDateChange = useCallback(
    (date, workspaceId, projectId = null, field) => {
      setFilterData((prevData) => {
        const newData = { ...prevData };
        const formattedDate = date.toISOString().split("T")[0];
        if (projectId) {
          const projectIndex = newData.filter_data.projects[
            workspaceId
          ].values.findIndex((p) => p.id === projectId);
          if (projectIndex !== -1) {
            newData.filter_data.projects[workspaceId].values[projectIndex][
              field
            ] = formattedDate;
          }
        } else {
          const workspaceIndex = newData.workspaces.findIndex(
            (w) => w.id === workspaceId,
          );
          if (workspaceIndex !== -1) {
            newData.workspaces[workspaceIndex][field] = formattedDate;
          }
        }
        return newData;
      });
    },
    [],
  );

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const response = await API.get(
          "initiatives/get_jira_filter_data_route",
        );
        const data = response.data.filter_data;
        const processId = response.data.process_id;

        // Store the process ID in state
        setProcessId(processId);

        // Initialize date values for workspaces and projects
        data.workspaces.forEach((workspace) => {
          workspace.created =
            workspace.created ||
            subDays(new Date(), 90).toISOString().split("T")[0];
          workspace.last_updated =
            workspace.last_updated ||
            subDays(new Date(), 90).toISOString().split("T")[0];
        });

        Object.values(data.filter_data.projects).forEach((workspace) => {
          workspace.values.forEach((project) => {
            project.created =
              project.created ||
              subDays(new Date(), 90).toISOString().split("T")[0];
            project.last_updated =
              project.last_updated ||
              subDays(new Date(), 90).toISOString().split("T")[0];
          });
        });

        setFilterData(data);
      } catch (error) {
        console.error("Error fetching Jira filter data:", error);
      }
    };

    fetchFilterData();
  }, []);

  const handleFilterChange = useCallback(
    (updatedFilterData, updatedWorkspaces) => {
      setFilterData((prevData) => ({
        ...prevData,
        filter_data: updatedFilterData,
        workspaces: updatedWorkspaces,
      }));
    },
    [],
  );

  const isWorkspaceChecked = useCallback(
    (workspaceId) => {
      return Object.entries(filterData.filter_data).some(([category, data]) => {
        if (category === "projects") {
          return (
            data[workspaceId] &&
            data[workspaceId].values.some((project) => project.match)
          );
        }
        return (
          data[workspaceId] &&
          ((data[workspaceId].global && data[workspaceId].global.match) ||
            (data[workspaceId].project_specific &&
              Object.values(data[workspaceId].project_specific).some(
                (project) => project.match,
              )))
        );
      });
    },
    [filterData],
  );

  const isProjectChecked = useCallback(
    (workspaceId, projectId) => {
      const project = filterData.filter_data.projects[workspaceId].values.find(
        (p) => p.id === projectId,
      );
      return project ? project.match : false;
    },
    [filterData],
  );

  const handleWorkspaceChange = useCallback(
    (workspaceId) => {
      setFilterData((prevData) => {
        const newMatch = !isWorkspaceChecked(workspaceId);
        const updatedFilterData = { ...prevData.filter_data };
        const updatedWorkspaces = [...prevData.workspaces];

        const workspaceIndex = updatedWorkspaces.findIndex(
          (w) => w.id === workspaceId,
        );
        if (workspaceIndex !== -1) {
          updatedWorkspaces[workspaceIndex].match = newMatch;
        }

        Object.keys(updatedFilterData).forEach((key) => {
          if (updatedFilterData[key][workspaceId]) {
            if (updatedFilterData[key][workspaceId].global) {
              updatedFilterData[key][workspaceId].global.match = newMatch;
              updatedFilterData[key][workspaceId].global.values.forEach(
                (item) => {
                  item.match = newMatch;
                },
              );
            }

            if (updatedFilterData[key][workspaceId].project_specific) {
              Object.values(
                updatedFilterData[key][workspaceId].project_specific,
              ).forEach((project) => {
                project.match = newMatch;
                project.values.forEach((item) => {
                  item.match = newMatch;
                });
              });
            }

            if (key === "projects") {
              updatedFilterData[key][workspaceId].match = newMatch;
              updatedFilterData[key][workspaceId].values.forEach((project) => {
                project.match = newMatch;
              });
            }
          }
        });

        return {
          ...prevData,
          filter_data: updatedFilterData,
          workspaces: updatedWorkspaces,
        };
      });
    },
    [isWorkspaceChecked],
  );

  const handleProjectChange = useCallback((workspaceId, projectId) => {
    setFilterData((prevData) => {
      const updatedFilterData = { ...prevData.filter_data };
      const updatedWorkspaces = [...prevData.workspaces];

      // Update project in the projects list
      const projectIndex = updatedFilterData.projects[
        workspaceId
      ].values.findIndex((p) => p.id === projectId);
      if (projectIndex !== -1) {
        const newMatch =
          !updatedFilterData.projects[workspaceId].values[projectIndex].match;
        updatedFilterData.projects[workspaceId].values[projectIndex].match =
          newMatch;

        // Update project-specific data if it exists
        Object.keys(updatedFilterData).forEach((key) => {
          if (
            updatedFilterData[key][workspaceId] &&
            updatedFilterData[key][workspaceId].project_specific &&
            updatedFilterData[key][workspaceId].project_specific[projectId]
          ) {
            updatedFilterData[key][workspaceId].project_specific[
              projectId
            ].match = newMatch;
            updatedFilterData[key][workspaceId].project_specific[
              projectId
            ].values.forEach((item) => {
              item.match = newMatch;
            });
          }
        });

        // Update workspace match status
        const workspaceMatch = updatedFilterData.projects[
          workspaceId
        ].values.some((project) => project.match);
        updatedFilterData.projects[workspaceId].match = workspaceMatch;

        const workspaceIndex = updatedWorkspaces.findIndex(
          (w) => w.id === workspaceId,
        );
        if (workspaceIndex !== -1) {
          updatedWorkspaces[workspaceIndex].match = workspaceMatch;
        }
      }

      return {
        ...prevData,
        filter_data: updatedFilterData,
        workspaces: updatedWorkspaces,
      };
    });
  }, []);

  const navigate = useNavigate();

  const handleSyncInitiatives = useCallback(async () => {
    try {
      console.log(
        "Data being sent to backend:",
        JSON.stringify(filterData, null, 2),
      );
      navigate("/data-integrations");
      const response = await API.post(
        "initiatives/custom-sync-initiatives-with-jira-route",
        {
          filter_data: filterData.filter_data,
          workspaces: filterData.workspaces,
          process_id: processId, // Include the process ID in the POST request
        },
      );
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      console.log("Sync initiated:", response.data);
    } catch (error) {
      console.error("Error initiating sync:", error);
      setIsCurrentlyCheckingForUpdatesOnProcesses(false);
      // You may want to show an error message to the user here
    }
  }, [
    filterData,
    navigate,
    setIsCurrentlyCheckingForUpdatesOnProcesses,
    processId,
  ]);

  if (!filterData) return <LoadingSpinner />;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">
        Jira Sync User Filter Selection
      </h1>
      {filterData.workspaces.map((workspace) => (
        <div
          key={workspace.id}
          className="mb-12 bg-white shadow-lg rounded-lg overflow-hidden"
        >
          <div className="bg-blue-600 text-white p-4 flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                id={`workspace-${workspace.id}`}
                checked={workspace.match}
                onChange={() => handleWorkspaceChange(workspace.id)}
                className="mr-2"
              />
              <h2 className="text-2xl font-semibold">{workspace.name}</h2>
            </div>
            <div className="flex items-center">
              <div className="mr-4">
                <label className="block text-sm font-medium mb-1">
                  Created
                </label>
                <DatePicker
                  selected={new Date(workspace.created)}
                  onChange={(date) =>
                    handleDateChange(date, workspace.id, null, "created")
                  }
                  dateFormat="yyyy-MM-dd"
                  className="bg-white text-black rounded p-2"
                  placeholderText="YYYY-MM-DD"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Last Updated
                </label>
                <DatePicker
                  selected={new Date(workspace.last_updated)}
                  onChange={(date) =>
                    handleDateChange(date, workspace.id, null, "last_updated")
                  }
                  dateFormat="yyyy-MM-dd"
                  className="bg-white text-black rounded p-2"
                  placeholderText="YYYY-MM-DD"
                />
              </div>
            </div>
          </div>
          <div className="p-6">
            {/* Show global data only once per workspace */}
            <ProjectSpecificDetails
              filterData={filterData.filter_data}
              workspaces={filterData.workspaces}
              workspaceId={workspace.id}
              projectId={null}
              onFilterChange={handleFilterChange}
            />
            <h3 className="text-xl font-medium mb-4 mt-8">Projects:</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filterData.filter_data.projects[workspace.id].values.map(
                (project) => (
                  <div
                    key={project.id}
                    className="flex flex-col items-start border p-4 rounded"
                  >
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={`project-${workspace.id}-${project.id}`}
                        checked={isProjectChecked(workspace.id, project.id)}
                        onChange={() =>
                          handleProjectChange(workspace.id, project.id)
                        }
                        className="mr-2"
                      />
                      <button
                        onClick={() =>
                          setSelectedProject({
                            workspaceId: workspace.id,
                            projectId: project.id,
                          })
                        }
                        className="bg-blue-50 hover:bg-blue-100 text-blue-700 font-medium py-2 px-4 rounded transition duration-300 ease-in-out"
                      >
                        {project.name}
                      </button>
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm font-medium mb-1">
                        Created
                      </label>
                      <DatePicker
                        selected={new Date(project.created)}
                        onChange={(date) =>
                          handleDateChange(
                            date,
                            workspace.id,
                            project.id,
                            "created",
                          )
                        }
                        dateFormat="yyyy-MM-dd"
                        className="bg-white border border-gray-300 rounded p-2 text-sm"
                        placeholderText="YYYY-MM-DD"
                      />
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm font-medium mb-1">
                        Last Updated
                      </label>
                      <DatePicker
                        selected={new Date(project.last_updated)}
                        onChange={(date) =>
                          handleDateChange(
                            date,
                            workspace.id,
                            project.id,
                            "last_updated",
                          )
                        }
                        dateFormat="yyyy-MM-dd"
                        className="bg-white border border-gray-300 rounded p-2 text-sm"
                        placeholderText="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                ),
              )}
            </div>
            {selectedProject &&
              selectedProject.workspaceId === workspace.id && (
                <ProjectSpecificDetails
                  filterData={filterData.filter_data}
                  workspaces={filterData.workspaces}
                  workspaceId={selectedProject.workspaceId}
                  projectId={selectedProject.projectId}
                  onFilterChange={handleFilterChange}
                />
              )}
          </div>
        </div>
      ))}

      <div className="mt-8 flex justify-center">
        <button
          onClick={handleSyncInitiatives}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
        >
          SYNC INITIATIVES WITH JIRA
        </button>
      </div>
    </div>
  );
};

export default JiraSyncUserFilterSelectionPage;
