import React, { useContext, useState, useEffect, useRef } from 'react';
import { StatusContext } from '../../contexts/StatusContext';
import { useAuth } from '../../contexts/AuthContext';
import API from '../../services/api';
import styled from 'styled-components';

const StatusBoxContainer = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: rgba(28, 28, 30, 0.95);
  color: #ffffff;
  padding: ${({ isMinimized }) => (isMinimized ? '12px' : '20px')};
  border-radius: 12px;
  z-index: 1000;
  transition: all 0.3s ease;
  min-width: ${({ isMinimized }) => (isMinimized ? 'auto' : '450px')};
  max-height: 400px;
  overflow-y: auto;
  font-size: ${({ isMinimized }) => (isMinimized ? '14px' : '16px')};
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  font-family: 'Inter', sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  background: none;
  border: none;
  color: #ffffff;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ProcessItem = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
`;

const ProcessTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProcessMessage = styled.p`
  font-size: 14px;
  opacity: 0.9;
  margin: 0;
  color: ${({ status }) => {
    if (status === 'error') return '#ff4d4d';
    if (status === 'complete') return '#4caf50';
    return 'inherit';
  }};
`;

const CancelButton = styled.button`
  cursor: pointer;
  font-size: 13px;
  background: none;
  border: 1px solid #ff4d4d;
  color: #ff4d4d;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #ff4d4d;
    color: #ffffff;
  }
`;

const StatusBox = () => {
  const { isCurrentlyCheckingForUpdatesOnProcesses, setIsCurrentlyCheckingForUpdatesOnProcesses, processes, animationDots } = useContext(StatusContext);
  const { user } = useAuth();
  const [isMinimized, setIsMinimized] = useState(false);
  const contentRef = useRef(null);
  const [lastManualScrollTime, setLastManualScrollTime] = useState(0);

  const handleClose = () => {
    setIsCurrentlyCheckingForUpdatesOnProcesses(false);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    const currentTime = Date.now();
    if (contentRef.current && currentTime - lastManualScrollTime >= 12000) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [processes, lastManualScrollTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setIsCurrentlyCheckingForUpdatesOnProcesses]);

  const handleCancelProcess = async (processId) => {
    try {
      await API.post('status-updates/cancel-process', { process_id: processId });
      // Optionally, you can update the UI immediately or wait for the next status update
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error('Error cancelling process:', error);
      // Optionally, show an error message to the user
    }
  };

  const sortProcesses = (processes) => {
    return [...processes].sort((a, b) => {
      if (a.is_complete === b.is_complete) return 0;
      return a.is_complete ? -1 : 1;
    });
  };

  if (!user || !isCurrentlyCheckingForUpdatesOnProcesses) {
    return null;
  }

  const getProcessMessage = (process) => {
    if (process.is_complete) {
      return process.status_messages[process.status_messages.length - 1];
    } else {
      return `${process.status_messages[process.status_messages.length - 1]}${animationDots}`;
    }
  };

  const handleManualScroll = () => {
    setLastManualScrollTime(Date.now());
  };

  return (
    <StatusBoxContainer isMinimized={isMinimized}>
      {!isMinimized && (
        <Header>
          <Title>Active Processes</Title>
          <ButtonGroup>
            <IconButton onClick={handleMinimize}>−</IconButton>
            <IconButton onClick={handleClose}>×</IconButton>
          </ButtonGroup>
        </Header>
      )}
      <Content 
        onClick={() => isMinimized && handleMinimize()} 
        ref={contentRef}
        onScroll={handleManualScroll}
      >
        {isMinimized ? (
          <Title>Active Processes: {processes.length}</Title>
        ) : (
          <>
            {sortProcesses(processes).map((process) => (
              <ProcessItem key={process.process_id}>
                <ProcessTitle>
                  {process.title_to_display_to_user}
                  {process.allow_cancellation && !process.is_complete && (
                    <CancelButton
                      onClick={() => handleCancelProcess(process.process_id)}
                    >
                      Cancel
                    </CancelButton>
                  )}
                </ProcessTitle>
                <ProcessMessage
                  status={
                    process.error_has_occurred
                      ? 'error'
                      : process.is_complete
                      ? 'complete'
                      : 'ongoing'
                  }
                >
                  {getProcessMessage(process)}
                </ProcessMessage>
              </ProcessItem>
            ))}
          </>
        )}
      </Content>
    </StatusBoxContainer>
  );
};

export default StatusBox;