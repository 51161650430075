import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WelcomeToXProdOnboardingFlowPage1 from "./WelcomeToXProdOnboardingFlowPage1";
import CreateInitiativesOnboardingFlowPage2 from "./CreateInitiativesOnboardingFlowPage2";
import AddDocumentsOnboardingFlowPage3 from "./AddDocumentsOnboardingFlowPage3";
import FindRelevantInsightsOnboardingFlowPage4 from "./FindRelevantInsightsOnboardingFlowPage4";
import YoureAllSetOnboardingFlowPage5 from "./YoureAllSetOnboardingFlowPage5";
import authService from "../auth/AuthService";

const OnboardingFlow = () => {
   const [currentStep, setCurrentStep] = useState(1);
   const [initiativeId, setInitiativeId] = useState(null);
   const navigate = useNavigate();

   const handleNextStep = (id = null) => {
      if (id) setInitiativeId(id);
      setCurrentStep((prevStep) => prevStep + 1);
   };

   const handleSkipStep = () => {
      setCurrentStep((prevStep) => prevStep + 1);
   };

   const handleupdateUserHasCompletedTheOnboardingFlowInLocalStorageAndDBAndGoToDashboard = async () => {
      console.log("in handleupdateUserHasCompletedTheOnboardingFlowInLocalStorageAndDBAndGoToDashboard");
      await authService.updateUserHasCompletedTheOnboardingFlowInLocalStorageAndDB(true);
      // Trigger a re-render by updating the state
      setCurrentStep((prevStep) => prevStep + 1);
      navigate("/initiatives");
   };

   const renderStep = () => {
      switch (currentStep) {
         case 1:
            return <WelcomeToXProdOnboardingFlowPage1 onNextStep={handleNextStep} />;
         case 2:
            return <CreateInitiativesOnboardingFlowPage2 onNextStep={handleNextStep} onSkipStep={handleSkipStep} />;
         case 3:
            return <AddDocumentsOnboardingFlowPage3 onNextStep={handleNextStep} onSkipStep={handleSkipStep} />;
         case 4:
            return (
               <FindRelevantInsightsOnboardingFlowPage4
                  onNextStep={handleNextStep}
                  onSkipStep={handleSkipStep}
                  initiativeId={initiativeId}
               />
            );
         case 5:
            return (
               <YoureAllSetOnboardingFlowPage5
                  onUpdateUserHasCompletedTheOnboardingFlowInLocalStorageAndDBAndGoToDashboard={
                     handleupdateUserHasCompletedTheOnboardingFlowInLocalStorageAndDBAndGoToDashboard
                  }
               />
            );
         default:
            return null;
      }
   };

   return (
      <div
         style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
         }}
      >
         {renderStep()}
      </div>
   );
};

export default OnboardingFlow;
