import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AuthService from "../../auth/AuthService";
import API from "../../../services/api";
import PrimaryButton from "../../reusable_components/Buttons/PrimaryButton";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateInitiativeModal = ({ open, onClose, onInitiativeCreated }) => {
  const [initiativeName, setInitiativeName] = useState("");
  const [initiativeDescription, setInitiativeDescription] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    setIsFormValid(initiativeName.trim() !== "" && initiativeDescription.trim() !== "");
  }, [initiativeName, initiativeDescription]);

  const handleCreateInitiative = async () => {
    const user = AuthService.getCurrentUser();
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    const newInitiative = {
      title: initiativeName,
      description: initiativeDescription,
      source: "manual",
      sourceRelatedMetadata: { createdAt: new Date().toISOString() },
      associatedUserId: user.user.userId,
      additional_initiative_information: {
        team: []
      }
    };

    try {
      const response = await API.post("initiatives-page/create-new-initiative", newInitiative);
      const createdInitiativeId = response.data._id;
      showNotification("Initiative created successfully!", "success");
      onInitiativeCreated(createdInitiativeId);
      onClose();
    } catch (error) {
      console.error("Error creating new initiative:", error);
      showNotification("Failed to create initiative.", "error");
    }
  };

  const showNotification = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <h1 className="self-stretch my-auto text-2xl text-slate-900">Create New Initiative</h1>
        </DialogTitle>
        <DialogContent>
          <p style={{
            color: '#727F95',
            fontFamily: '"Roboto Mono"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '135%',
            marginBottom: '16px'
          }}>
            Creating initiatives helps you prioritize roadmap items, supported by intelligent insights and ROI predictions
          </p>
          <div
            className="flex items-center gap-2 px-5 py-[9px] rounded-xl border border-[#E5EAF3] w-[100%] mt-16"
            style={{ background: "white", borderColor: "#E5EAF3" }}
          >
            <input
              type="text"
              placeholder="Enter a name"
              maxLength="200"
              value={initiativeName}
              onChange={(e) => setInitiativeName(e.target.value)}
              className="flex-1 bg-transparent focus:outline-none placeholder-[#727F95]"
              style={{
                color: initiativeName ? "var(--blue-darkest, #001331)" : "#727F95",
                fontFamily: '"Roboto Mono"',
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "150%"
              }}
            />
            <div className="w-[39px] text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%]">
              {initiativeName.length}/200
            </div>
          </div>
          <div
            className="flex flex-col h-[150px] rounded-xl border border-[#E5EAF3] w-[100%] mt-6"
            style={{ background: "white", borderColor: "#E5EAF3" }}
          >
            <div className="flex flex-1 flex-col px-5 py-[9px] gap-2">
              <textarea
                placeholder="Description"
                maxLength="9000"
                value={initiativeDescription}
                onChange={(e) => setInitiativeDescription(e.target.value)}
                className="flex-1 bg-transparent resize-none focus:outline-none placeholder-[#727F95]"
                style={{
                  color: initiativeDescription ? "var(--blue-darkest, #001331)" : "#727F95",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%"
                }}
              ></textarea>
            </div>
            <div className="self-stretch text-right text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%] px-5 pb-[9px]">
              {initiativeDescription.length}/9,000
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={onClose} color="primary">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleCreateInitiative} disabled={!isFormValid} color="primary">
            Create Initiative
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateInitiativeModal;