import React, { useState, useEffect, useContext } from "react";
import {
  Menu,
  MenuItem,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import API from "../../../services/api";
import PageTitle from "../../reusable_components/HeadingsAndTitles/PageTitle";
import log from "../../../utils/logger";
import documentTypes from "../../../ConstantVariables.json";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../../contexts/StatusContext";
import LoadingSpinner from "../../reusable_components/LoadingSpinner/LoadingSpinner";
import PrimaryButton from "../../reusable_components/Buttons/PrimaryButton";
import ClassifyFilesTable from "./ClassifyFilesTable";
import UploadFileIcon from "../../../assets/icons/UploadFileIcon.svg";
import { initiateFileUpload } from "../../../services/localFileUploadService";
import { useIntegrations } from '../../../contexts/IntegrationsContext'; // Import useIntegrations

// Custom styled Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LocalDocumentsTabContent = () => {
  const [files, setFiles] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDocumentType, setBulkDocumentType] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    key: 0,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } =
    useContext(StatusContext);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { numLocalFilesUploaded, refreshIntegrations } = useIntegrations();

  useEffect(() => {
    fetchFiles();
  }, [numLocalFilesUploaded]);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await API.get("file-management/classify-files");
      const sortedFiles = sortFilesByDocumentType(response.data);
      setFiles(sortedFiles);
      setInitialDocumentTypes(sortedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
      showNotification("Failed to fetch files.", "error");
    } finally {
      setLoading(false);
    }
  };

  const sortFilesByDocumentType = (files) => {
    return files.sort((a, b) => {
      if (!a.documentType && b.documentType) return -1;
      if (a.documentType && !b.documentType) return 1;
      return 0;
    });
  };

  const setInitialDocumentTypes = (files) => {
    const initialDocumentTypes = {};
    files.forEach((file) => {
      initialDocumentTypes[file.id] = file.documentType || "";
    });
    setSelectedDocumentTypes(initialDocumentTypes);
  };

  const showNotification = (message, severity) => {
    setSnackbar((prev) => ({
      open: true,
      message,
      severity,
      key: prev.key + 1,
    }));
  };

  const handleDocumentTypeChange = async (fileId, documentType) => {
    setSelectedDocumentTypes((prevTypes) => ({
      ...prevTypes,
      [fileId]: documentType,
    }));

    try {
      await API.post("file-management/update-file-metadata", {
        files: [files.find((file) => file.id === fileId)],
        selectedDocumentTypes: { [fileId]: documentType },
      });
      showNotification("Document type has been successfully saved.", "success");
      log("Kicking off insights extraction process");
      await API.post("/insights/extract_insights_for_user");
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error("Error updating file metadata:", error);
      showNotification("Failed to save document type.", "error");
    }
  };

  const handleOptionsClick = (event, fileId) => {
    setAnchorEl(event.currentTarget);
    setSelectedFileId(fileId);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
    setSelectedFileId(null);
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteConfirmOpen(false);
    try {
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      const response = await API.delete(
        `file-management/delete-files-and-metadata`,
        {
          data: { file_ids: selectedRows },
        },
      );
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      if (response.data.process_id) {
        setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      }
    //   setFiles((prevFiles) =>
    //     prevFiles.filter((file) => !selectedRows.includes(file.id)),
    //   );
      setSelectedRows([]);
      showNotification("File deletion process has started.", "info");
      
      // Call refreshIntegrations only if a single file is being deleted
      if (selectedRows.length === 1) {
        refreshIntegrations();
      }
    } catch (error) {
      console.error("Error deleting files:", error);
      showNotification("Failed to start file deletion process.", "error");
    }
  };

  const handleBulkDocumentTypeChange = (event) => {
    setBulkDocumentType(event.target.value);
  };

  const applyBulkDocumentType = async () => {
    log("Applying bulk document type:", bulkDocumentType);
    log("Selected rows for bulk update:", selectedRows);
    log("Current document types before update:", selectedDocumentTypes);
    const updatedDocumentTypes = { ...selectedDocumentTypes };
    selectedRows.forEach((rowId) => {
      updatedDocumentTypes[rowId] = bulkDocumentType;
    });
    setSelectedDocumentTypes(updatedDocumentTypes);

    try {
      await API.post("file-management/update-file-metadata", {
        files: files.filter((file) => selectedRows.includes(file.id)),
        selectedDocumentTypes: updatedDocumentTypes,
      });
      showNotification(
        "Bulk document type has been successfully saved.",
        "success",
      );

      log("Kicking off insights extraction process for bulk update");
      await API.post("/insights/extract_insights_for_user");
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error("Error updating file metadata:", error);
      showNotification("Failed to save bulk document type.", "error");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const renderBatchActions = () => {
    if (selectedRows.length === 0) return null;

    return (
      <div>
        <select
          value={bulkDocumentType}
          onChange={handleBulkDocumentTypeChange}
        >
          <option value="">Batch Select Document Type</option>
          {Object.keys(documentTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        <button
          onClick={applyBulkDocumentType}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          Apply to Selected
        </button>
        <button
          onClick={handleDeleteConfirm}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          Delete
        </button>
      </div>
    );
  };

  const handleUploadClick = () => {
    initiateFileUpload(
      setIsCurrentlyCheckingForUpdatesOnProcesses,
      navigate,
      () => {
        // Call refreshIntegrations after upload completes
        refreshIntegrations();
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        paddingBottom: "200px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "36px",
        alignSelf: "stretch",
      }}
    >
      {loading ? (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          id="text-and-upload-documents-button-and-classify-files-table"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "36px",
          }}
        >
          <div
            id="text-and-upload-documents-button"
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "16px",
              alignSelf: "stretch",
            }}
          >
            <div id="Text-instructions"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <p
                style={{
                  alignSelf: "stretch",
                  color: "var(--blue-darkest, #001331)",
                  fontFamily: "Rubik",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "145%",
                }}
              >
                Upload local files to automatically identify insights related to your initiatives
              </p>
              <p
                style={{
                  alignSelf: "stretch",
                  color: "var(--slate-dark, #727F95)",
                  fontFamily: "Roboto Mono",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "130%",
                }}
              >
                Formats accepted: csv, pdf, txt, docx, mp4, mp3, mov, avi, wav, mkv, wmv, m4v, aac, flac, ogg, webm, mpg, mpeg, flv, 3gp.
              </p>
            </div>
            <PrimaryButton
              icon={UploadFileIcon}
              onClick={handleUploadClick}
            >
              Upload Documents
            </PrimaryButton>
          </div>
          {renderBatchActions()}
          <ClassifyFilesTable
            files={files}
            selectedDocumentTypes={selectedDocumentTypes}
            handleDocumentTypeChange={handleDocumentTypeChange}
            handleOptionsClick={handleOptionsClick}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
      >
        <MenuItem onClick={handleDeleteConfirm}>Delete</MenuItem>
      </Menu>
      <Snackbar
        key={snackbar.key}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <h1 className="self-stretch my-auto text-2xl text-slate-900">
            Confirm Deletion
          </h1>
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              color: "#727F95",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "135%",
              marginBottom: "16px",
            }}
          >
            Are you sure you want to delete this file(s)? This will also delete
            all insights extracted from them.
          </p>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleDeleteCancel} color="primary">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleDeleteConfirmed} color="primary">
            Delete
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LocalDocumentsTabContent;
