import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import { Tooltip, IconButton, styled, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import documentTypes from "../../../ConstantVariables.json";

// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
   ({ theme }) => ({
      "& .MuiTooltip-tooltip": {
         backgroundColor: theme.palette.background.paper,
         color: theme.palette.text.primary,
         maxWidth: 320,
         fontSize: theme.typography.pxToRem(12),
         border: "1px solid #dadde9",
         boxShadow: theme.shadows[1],
         borderRadius: theme.shape.borderRadius,
         padding: theme.spacing(2)
      }
   })
);

const ClassifyFilesTable = ({
   files,
   selectedDocumentTypes,
   handleDocumentTypeChange,
   handleOptionsClick,
   selectedRows,
   setSelectedRows
}) => {
   const navigate = useNavigate();

   const documentTypeTooltipContent = (
      <div>
         {Object.entries(documentTypes).map(([type, { description }]) => (
            <div key={type} style={{ marginBottom: "8px" }}>
               <strong style={{ color: "#1976d2" }}>{type}:</strong>
               <p style={{ margin: "4px 0 0 0" }}>{description}</p>
            </div>
         ))}
      </div>
   );

   const preventSelectionOnClick = (event) => {
      event.stopPropagation();
   };

   const columns = [
      {
         field: "actions",
         headerName: "",
         width: 30,
         renderCell: (params) => (
            <GridActionsCellItem
               icon={<MoreVertIcon />}
               onClick={(event) => handleOptionsClick(event, params.row.id)}
               label="Options"
            />
         )
      },
      {
         field: "fileName",
         headerName: "File Name",
         width: 350,
         renderCell: (params) => (
            <div
               className="cursor-pointer text-blue-600 hover:underline"
               onClick={() => navigate(`/doc-viewer/${params.row.id}`)}
            >
               {params.value}
            </div>
         )
      },
      { field: "fileExtension", headerName: "File Extension", width: 120 },
      {
         field: "documentType",
         headerName: "Document Type",
         width: 350,
         renderHeader: (params) => (
            <div style={{ display: "flex", alignItems: "center" }}>
               {params.colDef.headerName}
               <StyledTooltip title={documentTypeTooltipContent} arrow placement="right-start">
                  <IconButton size="small" style={{ marginLeft: "4px" }}>
                     <InfoIcon fontSize="small" color="action" />
                  </IconButton>
               </StyledTooltip>
            </div>
         ),
         renderCell: (params) => (
            <div onClick={preventSelectionOnClick} className="w-full h-full flex items-center">
               {params.row.s3Status ? (
                  <select
                     className={`w-full ${selectedDocumentTypes[params.row.id] === "" ? "bg-red-200" : ""}`}
                     value={selectedDocumentTypes[params.row.id] || ""}
                     onChange={(e) => handleDocumentTypeChange(params.row.id, e.target.value)}
                  >
                     <option value="">Select Document Type</option>
                     {Object.keys(documentTypes).map((type) => (
                        <option key={type} value={type}>
                           {type}
                        </option>
                     ))}
                  </select>
               ) : (
                  <Typography variant="body2" color="textSecondary">
                     Processing...
                  </Typography>
               )}
            </div>
         ),
         cellClassName: (params) => (params.row.s3Status
            ? selectedDocumentTypes[params.row.id] === ""
               ? "bg-red-200"
               : ""
            : "bg-gray-200 cursor-not-allowed")
      },
      {
         field: "s3Status",
         headerName: "Status",
         width: 120,
         renderCell: (params) => (
            <Typography
               variant="body2"
               color={params.value ? "textPrimary" : "textSecondary"}
            >
               {params.value ? "Ready" : "Processing"}
            </Typography>
         )
      }
   ];

   const totalColumnWidth = columns.reduce((total, column) => total + (column.width || 0), 0);
   const minGridWidth = totalColumnWidth + 100;

   return (
      <div className="pt-5 pb-5" style={{ height: 400, width: "100%", minWidth: `${minGridWidth}px` }}>
         <DataGrid
            rows={files}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
               const filteredSelection = newSelection.filter(id => 
                  files.find(file => file.id === id)?.s3Status
               );
               setSelectedRows(filteredSelection);
            }}
            selectionModel={selectedRows}
            disableRowSelectionOnClick
            isRowSelectable={(params) => params.row.s3Status}
            sx={{
               [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                  outline: "none"
               },
               '& .MuiDataGrid-row.Mui-selected': {
                  backgroundColor: 'transparent',
               },
               '& .MuiDataGrid-row.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
               },
               '& .MuiDataGrid-row:not(.Mui-selected):hover': {
                  backgroundColor: (theme) =>
                     theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
               },
               '& .MuiDataGrid-row.processing': {
                  backgroundColor: (theme) =>
                     theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
                  pointerEvents: 'none',
                  opacity: 0.7,
               },
            }}
            getRowClassName={(params) => params.row.s3Status ? '' : 'processing'}
         />
      </div>
   );
};

export default ClassifyFilesTable;