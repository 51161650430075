import React, { useEffect, useState } from "react";
import { FaFileAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import API from "../../services/api";

function DocWhereInsightIsMentionedCard({
   insight_id,
   filemetadata_doc_id,
   choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight
}) {
   const [mentions, setMentions] = useState([]);
   const [currentMentionIndex, setCurrentMentionIndex] = useState(0);
   const [fileMetadata, setFileMetadata] = useState(null);
   const navigate = useNavigate();

   useEffect(() => {
      const fetchMentionsAndMetadata = async () => {
         try {
            const mentionsResponse = await API.get(
               `/doc-viewer-page/get-list-of-mentions-of-this-insight-in-this-document/${insight_id}/${filemetadata_doc_id}/${choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight}`
            );
            setMentions(mentionsResponse.data);

            const metadataResponse = await API.get(`/insights/get-filemetadata-doc-info/${filemetadata_doc_id}`);
            setFileMetadata(metadataResponse.data);
         } catch (error) {
            console.error("Error fetching mentions and metadata:", error);
         }
      };
      fetchMentionsAndMetadata();
   }, [insight_id, filemetadata_doc_id, choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight]);

   const handlePrevious = () => {
      setCurrentMentionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mentions.length - 1));
   };

   const handleNext = () => {
      setCurrentMentionIndex((prevIndex) => (prevIndex < mentions.length - 1 ? prevIndex + 1 : 0));
   };

   const handleMentionClick = (mention) => {
      console.log("Mention clicked:", {
         mentionData: mention,
         insightIdentifier: insight_id,
         fileMetadataDocumentId: filemetadata_doc_id,
         insightType: choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight,
         fileMetadataInfo: fileMetadata
      });
      const linkUrl = `/doc-viewer/${filemetadata_doc_id}/${mention.id}`;
      navigate(linkUrl);
   };

   if (!fileMetadata || mentions.length === 0) {
      return <div>Loading...</div>;
   }

   const currentMention = mentions[currentMentionIndex];
   const linkUrl = `/doc-viewer/${filemetadata_doc_id}/${currentMention.id}`;

   return (
      <div
         style={{
            display: "flex",
            width: "360px",
            padding: "16px 20px 24px 20px",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            gap: "20px",
            borderRadius: "12px",
            border: "1px solid var(--slate-lighter, #E5EAF3)",
            background: "var(--white, #FFF)"
         }}
      >
         <div
            id="AllContentContainer"
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "flex-start",
               gap: "12px",
               alignSelf: "stretch"
            }}
         >
            <div
               id="FileNameAndFileType"
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "6px",
                  alignSelf: "stretch"
               }}
            >
               <div
                  id="FileNameAndArrow"
                  style={{
                     display: "flex",
                     alignItems: "center",
                     gap: "8px",
                     alignSelf: "stretch",
                     overflow: "hidden" // Add this to prevent content from overflowing
                  }}
               >
                  <div
                     style={{
                        flex: "1 0 0",
                        color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                        fontFamily: "Rubik",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "145%",
                        whiteSpace: "nowrap", // Force single line
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis for overflowing text
                     }}
                  >
                     {fileMetadata.file_name}
                  </div>
                  <div
                     onClick={() => handleMentionClick(currentMention)}
                     style={{ cursor: "pointer" }}
                  >
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ flexShrink: 0 }}>
                        <path
                           d="M5.55375 19.5L4.5 18.4462L15.9462 7H9V5.5H18.5V15H17V8.05375L5.55375 19.5Z"
                           fill="#628FDA"
                        />
                     </svg>
                  </div>
               </div>
               <div
                  id="FileType"
                  style={{
                     alignSelf: "stretch",
                     color: "var(--slate-dark, #727F95)",
                     fontFamily: '"Roboto Mono"',
                     fontSize: "14px",
                     fontStyle: "normal",
                     fontWeight: 500,
                     lineHeight: "135%",
                     letterSpacing: "0.28px",
                     textTransform: "uppercase"
                  }}
               >
                  {fileMetadata.document_type}
               </div>
            </div>
            <div
               id="QuoteText"
               style={{
                  width: "319px",
                  height: "88px",
                  color: "var(--blue-darkest, #001331)",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "italic",
                  fontWeight: 400,
                  lineHeight: "140%",
                  overflowY: "auto",
                  cursor: "pointer"
               }}
               onClick={() => handleMentionClick(currentMention)}
            >
               {currentMention.span_text}
            </div>
            <div
               id="LeftRightArrowContainer"
               style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  alignSelf: "stretch"
               }}
            >
               <div
                  id="LeftArrow"
                  style={{
                     width: "24px",
                     height: "24px",
                     cursor: "pointer"
                  }}
                  onClick={handlePrevious}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M10.6994 12L15.5 16.8817L14.4003 18L8.5 12L14.4003 6L15.5 7.11828L10.6994 12Z"
                        fill="#104499"
                     />
                  </svg>
               </div>
               <div
                  id="NumberAndTotalText"
                  style={{
                     color: "var(--slate-dark, #727F95)",
                     fontFamily: '"Roboto Mono"',
                     fontSize: "16px",
                     fontStyle: "normal",
                     fontWeight: 400,
                     lineHeight: "130%"
                  }}
               >
                  {currentMentionIndex + 1}/{mentions.length}
               </div>
               <div
                  id="RightArrow"
                  style={{
                     width: "24px",
                     height: "24px",
                     cursor: "pointer"
                  }}
                  onClick={handleNext}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M13.3006 12L8.5 7.11828L9.5997 6L15.5 12L9.5997 18L8.5 16.8817L13.3006 12Z"
                        fill="#104499"
                     />
                  </svg>
               </div>
            </div>
         </div>
      </div>
   );
}

export default DocWhereInsightIsMentionedCard;
