import React from "react";
import { useNavigate } from "react-router-dom";
import H2Black from "../reusable_components/HeadingsAndTitles/H2Black";
import H3Gray from "../reusable_components/HeadingsAndTitles/H3Gray";
import OnboardingFlowProgressBar from "./OnboardingFlowProgressBar";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow from "./DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow";
import AuthService from "../auth/AuthService";

const YoureAllSetOnboardingFlowPage5 = ({
   onUpdateUserHasCompletedTheOnboardingFlowInLocalStorageAndDBAndGoToDashboard
}) => {
   const navigate = useNavigate();

   const handleCompletion = async () => {
      await AuthService.updateUserHasCompletedTheOnboardingFlowInLocalStorageAndDB(true);
      navigate("/initiatives"); // Navigate to the root path, which is InitiativesPage
   };

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "687px",
            maxWidth: "880px"
         }}
      >
         <OnboardingFlowProgressBar currentStep={3} totalSteps={3} />
         <H2Black style={{ textAlign: "center" }}>You're all set!</H2Black>
         <H3Gray style={{ textAlign: "left", marginTop: "12px", marginBottom: "64px" }}>
            You can add more initiatives and extract insights from your dashboard
         </H3Gray>
         <DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow relatedInsightsHaveAlreadyBeenFound={true} />
         <PrimaryButton
            onClick={handleCompletion}
            style={{
               marginTop: "64px"
            }}
         >
            Go to Dashboard
         </PrimaryButton>
      </div>
   );
};

export default YoureAllSetOnboardingFlowPage5;
