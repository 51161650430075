import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Add this import
import API from "../../services/api";
import log from "../../utils/logger";

function SingleIndividualPainPointMenu({ insightId, onClose }) {
   const navigate = useNavigate(); // Add this line
   const { id } = useParams(); // Add this line
   const [insight, setInsight] = useState(null);
   const [fileMetadata, setFileMetadata] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   useEffect(() => {
      const fetchInsight = async () => {
         try {
            const response = await API.get(`insights/get-individual-insight/${insightId}`);
            console.log(response.data);
            setInsight(response.data.individual_insight);
            setFileMetadata(response.data.file_metadata);
            setLoading(false);
            // Update URL to include individual pain point ID
            // navigate(`/insights/individual-pain-point/${id}`, { replace: true });
         } catch (err) {
            log("Error fetching individual pain point insight:", err);
            setError("Failed to load insight. Please try again later.");
            setLoading(false);
         }
      };

      fetchInsight();
   }, [insightId]);

   const handleFileMetadataClick = () => {
      if (fileMetadata && fileMetadata.id) {
         navigate(`/doc-viewer/${fileMetadata.id}`);
      }
   };

   if (loading) return <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>;
   if (error) return <div className="text-red-600">{error}</div>;
   if (!insight) return <div className="text-gray-600">No insight found</div>;

   return (
      <>
         <div 
            className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-md z-[1999]" 
            onClick={onClose}
         ></div>
         <div className="fixed inset-y-0 right-0 w-2/5 bg-white shadow-lg overflow-y-auto z-[2000] ml-[60px]">
            <div className="p-6">
               <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
               </button>
               <h2 className="text-[#104499] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase mb-2">
                  PAIN POINT
               </h2>
               <h3 className="flex-1 text-[#001331] font-['Roboto_Mono'] text-2xl font-medium leading-[135%] mb-4">
                  {insight.title}
               </h3>

               {fileMetadata && (
                  <div 
                     className="flex flex-col justify-end items-start gap-5 flex-1 p-[16px_20px_24px_20px] rounded-xl border border-[#E5EAF3] bg-white mb-4 cursor-pointer hover:bg-gray-100"
                     onClick={handleFileMetadataClick}
                  >
                     <div className="flex justify-between items-center w-full">
                        <span className="text-[#001331] font-['Rubik'] text-base font-medium leading-[145%] flex-1">
                           {fileMetadata.file_name}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <path d="M5.55375 19.5L4.5 18.4462L15.9462 7H9V5.5H18.5V15H17V8.05375L5.55375 19.5Z" fill="#628FDA"/>
                        </svg>
                     </div>
                     <span className="text-[#727F95] font-['Roboto_Mono'] text-sm font-medium leading-[135%] tracking-[0.28px] uppercase self-stretch">
                        {fileMetadata.document_type}
                     </span>
                  </div>
               )}

               <div className="mb-4">
                  <h3 className="h-[22px] self-stretch text-[#001331] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase">
                     Summary
                  </h3>
                  <p className="self-stretch text-[#001331] font-['Rubik'] text-base font-normal leading-[145%]">
                     {insight.summary}
                  </p>
               </div>

               <div className="mb-4">
                  <h3 className="h-[22px] self-stretch text-[#001331] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase">
                     Description
                  </h3>
                  <p className="self-stretch text-[#001331] font-['Rubik'] text-base font-normal leading-[145%]">
                     {insight.description_long_version}
                  </p>
               </div>

               <div className="mb-4">
                  <h3 className="h-[22px] self-stretch text-[#001331] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase">
                     Importance
                  </h3>
                  <div className="self-stretch text-[#001331] font-['Rubik'] text-base font-normal leading-[145%]">
                     <p className="mb-2"><strong>Specifics:</strong> {insight.specifics_long_version}</p>
                     <p><strong>Why Them:</strong> {insight.why_them_long_version}</p>
                  </div>
               </div>

               <div className="mb-4">
                  <h3 className="h-[22px] self-stretch text-[#001331] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase">
                     Metrics Impacted
                  </h3>
                  <ul className="self-stretch text-[#001331] font-['Rubik'] text-base font-normal leading-[145%] list-disc list-inside">
                     {insight.metrics_impacted.map((item, index) => (
                        <li key={index}>{item}</li>
                     ))}
                  </ul>
               </div>

               <div className="mb-4">
                  <h3 className="h-[22px] self-stretch text-[#001331] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase">
                     Cost
                  </h3>
                  <div className="self-stretch text-[#001331] font-['Rubik'] text-base font-normal leading-[145%]">
                     <p className="mb-2"><strong>Primary Costs:</strong></p>
                     <ul className="list-disc list-inside mb-4">
                        {insight.primary_costs.map((cost, index) => (
                           <li key={index}>{cost}</li>
                        ))}
                     </ul>
                     <p className="mb-2"><strong>Implied Costs:</strong></p>
                     <ul className="list-disc list-inside">
                        {insight.implied_costs.map((cost, index) => (
                           <li key={index}>{cost}</li>
                        ))}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default SingleIndividualPainPointMenu;
