import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubsectionDropdownHeader from "../SubsectionDropdownHeader";
import QuoteCard from "../QuoteCard";

const IndividualFeatureRequestsCardScroller = ({ individualFeatureRequestsList, onFeatureRequestClick }) => {
   const [isContentVisible, setIsContentVisible] = useState(true);
   const navigate = useNavigate();

   const toggleContentVisibility = () => {
      setIsContentVisible(!isContentVisible);
   };

   const handleFeatureRequestClick = (featureRequestId) => {
      if (onFeatureRequestClick) {
         onFeatureRequestClick(featureRequestId);
      } else {
         navigate(`/insights/individual-feature-request/${featureRequestId}`);
      }
   };

   return (
      <div
         className="individual-feature-requests-card-scroller"
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch"
         }}
      >
         <div
            style={{
               width: "100%",
               height: "1px",
               background: "var(--slate-light, #C5D1E4)"
            }}
         ></div>
         <SubsectionDropdownHeader
            count={individualFeatureRequestsList.length}
            sectionHeader="Individual Feature Requests"
            isExpanded={isContentVisible}
            onToggle={toggleContentVisibility}
         />
         {isContentVisible && (
            <div
               style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "24px",
                  alignSelf: "stretch",
                  overflowX: "auto",
                  paddingBottom: "16px"
               }}
            >
               {individualFeatureRequestsList.map((featureRequest) => (
                  <QuoteCard
                     key={featureRequest._id}
                     title={featureRequest.title}
                     quote_text={featureRequest.impactful_quotes[0]}
                     source_file_name={featureRequest.source_document_name}
                     isClickable={true}
                     onClick={() => handleFeatureRequestClick(featureRequest._id)}
                     style={{
                        minWidth: "360px",
                        maxWidth: "360px"
                     }}
                     arrowAndLeftLineColor="#628FDA"
                  />
               ))}
            </div>
         )}
         <div
            style={{
               width: "100%",
               height: "1px",
               background: "var(--slate-light, #C5D1E4)"
            }}
         ></div>
      </div>
   );
};

export default IndividualFeatureRequestsCardScroller;