import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StatusContext } from '../../../contexts/StatusContext';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';
import { uploadFiles } from '../../../services/localFileUploadService';
import API from '../../../services/api';

const CsvViewer = () => {
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);
  const [csvFiles, setCsvFiles] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [parsedColumns, setParsedColumns] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.csvFiles) {
      const files = location.state.csvFiles;
      setCsvFiles(files);
      uploadAndParseCsvFiles(files);
    } else {
      console.log('No CSV files received.');
      setIsLoading(false);
    }
  }, [location.state]);

  const uploadAndParseCsvFiles = async (files) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      // Assign IDs to files and append to formData
      const updatedFiles = files.map((file, index) => {
        const fileId = `file${index}`;
        formData.append(fileId, file);
        return { id: fileId, name: file.name }; // Include name explicitly
      });

      const response = await API.post('/local_file_uploads/parse-csv-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setParsedColumns(response.data);
      initializeSelectedColumns(response.data);
      setCsvFiles(updatedFiles); // Update csvFiles with assigned IDs and names
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading and parsing CSV files:', error);
      setIsLoading(false);
    }
  };

  const initializeSelectedColumns = (columnsData) => {
    const initialSelectedColumns = {};
    Object.keys(columnsData).forEach((fileId) => {
      initialSelectedColumns[fileId] = columnsData[fileId].reduce((acc, column) => {
        acc[column] = true;
        return acc;
      }, {});
    });
    setSelectedColumns(initialSelectedColumns);
  };

  const handleColumnToggle = (fileId, column) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [fileId]: {
        ...prev[fileId],
        [column]: !prev[fileId][column],
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const filesDataPromises = csvFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (event) {
            const fileContent = event.target.result;
            resolve({
              id: file.id,
              name: file.name,
              content: fileContent,
              selectedColumns: Object.keys(selectedColumns[file.id]).filter(
                (column) => selectedColumns[file.id][column]
              ),
            });
          };
          reader.onerror = function (event) {
            reject(event.target.error);
          };
          reader.readAsText(location.state.csvFiles.find((f) => f.name === file.name));
        });
      });

      const filesData = await Promise.all(filesDataPromises);
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      API.post('/local_file_uploads/process-selected-columns', {
        files: filesData,
      });
      
      navigate('/data-integrations');

    } catch (error) {
      console.error('Error processing selected columns:', error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Select CSV Columns to Process</h1>
      {isLoading ? (
        <p>Loading CSV files...</p>
      ) : csvFiles.length > 0 ? (
        csvFiles.map((file) => {
          return (
            <div key={file.id} className="mb-6">
              <h2 className="text-xl font-semibold mb-2">{file.name}</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {parsedColumns[file.id] && parsedColumns[file.id].length > 0 ? (
                  parsedColumns[file.id].map((column) => (
                    <div key={`${file.id}-${column}`} className="flex items-center">
                      <button
                        onClick={() => handleColumnToggle(file.id, column)}
                        className="mr-2 text-blue-600 focus:outline-none"
                      >
                        {selectedColumns[file.id]?.[column] ? (
                          <FaCheckSquare className="w-5 h-5" />
                        ) : (
                          <FaSquare className="w-5 h-5" />
                        )}
                      </button>
                      <label className="text-sm">{column}</label>
                    </div>
                  ))
                ) : (
                  <p>No columns found in the CSV file.</p>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <p>No CSV files loaded. Please select CSV files to view.</p>
      )}
      <button
        onClick={handleSubmit}
        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Process Selected Columns
      </button>
    </div>
  );
};

export default CsvViewer;
