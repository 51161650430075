import React, { useContext } from "react";
import IntegrationCard from "../IntegrationCard";
import { useIntegrations } from "../../../contexts/IntegrationsContext";
import { useAuth } from "../../../contexts/AuthContext";
import API from "../../../services/api"; // Import the API instance
import {
  initiateJiraOAuth,
  handleRemoveIntegration,
  initiateSlackOAuth
} from "../integrationOAuthHelpers";
import {
  getSupportedFileTypes,
  uploadFiles,
} from "../../../services/localFileUploadService";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../../contexts/StatusContext";
import JiraIntegrationLogo from "../../../assets/icons/ExternalIntegrationsIcons/jira_logo.svg";
import SteamLogo from "../../../assets/icons/ExternalIntegrationsIcons/steam_logo.svg";
import DocIcon from "../../../assets/icons/DocIcon.svg";
import UploadFileIcon from "../../../assets/icons/UploadFileIcon.svg";
import { initiateFileUpload } from "../../../services/localFileUploadService";
import SlackLogo from "../../../assets/icons/ExternalIntegrationsIcons/slack.svg";

const AllIntegrationsTabContent = ({ onTabChange }) => {
  const { numLocalFilesUploaded, integrations, refreshIntegrations, activateSteam } = useIntegrations();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } =
    useContext(StatusContext);

  const handleJiraIntegration = () => {
    initiateJiraOAuth(user);
  };

  const handleJiraDisconnect = () => {
    handleRemoveIntegration("jira");
  };

  const handleJiraConfigureClick = () => {
    onTabChange("Jira");
  };

  const jiraDropdownOptions = [
    { label: "Remove Integration", onClick: handleJiraDisconnect },
    { label: "Configure", onClick: handleJiraConfigureClick }, // Updated this line
  ];

  const handleJiraConnectedChipClick = () => {
    handleRemoveIntegration("jira");
  };

  const handleLocalDocumentsUpload = () => {
    initiateFileUpload(
      setIsCurrentlyCheckingForUpdatesOnProcesses,
      navigate,
      () => {
        // Call refreshIntegrations after upload completes
        refreshIntegrations();
      },
    );
  };

  const handleLocalDocumentsChipClick = () => {
    onTabChange("Local Documents");
  };

  const handleSteamIntegration = async () => {
    try {
      await activateSteam();
      console.log("Steam integration activated successfully");
      refreshIntegrations(); // Refresh the integrations state after activation
    } catch (error) {
      console.error("Failed to activate Steam integration:", error);
      // Optionally, you can show an error message to the user
    }
  };

  const handleSteamDisconnect = async () => {
    try {
      const response = await API.post('steam/uninstall-steam-integration');
      if (response.status === 202) {
        console.log("Steam integration uninstallation process started");
        // Optionally, you can show a success message to the user
        // You might want to refresh the integrations state after a short delay
        refreshIntegrations()
      }
    } catch (error) {
      console.error("Failed to uninstall Steam integration:", error);
      // Optionally, you can show an error message to the user
    }
  };

  const handleSteamConfigureClick = () => {
    onTabChange("Steam");
  };

  const steamDropdownOptions = [
    { label: "Remove Integration", onClick: handleSteamDisconnect },
    { label: "Configure", onClick: handleSteamConfigureClick },
  ];

  const handleSlackIntegration = () => {
    initiateSlackOAuth(user);
  };

  const handleSlackDisconnect = async () => {
    try {
      const response = await API.post('slack/oauth/uninstall');
      if (response.status === 200) {
        console.log("Slack integration disconnected successfully");
        refreshIntegrations(); // Refresh the integrations state after disconnection
      }
    } catch (error) {
      console.error("Failed to disconnect Slack integration:", error);
      // Optionally, you can show an error message to the user
    }
  };

  const handleSlackConfigureClick = () => {
    onTabChange("Slack");
  };

  const slackDropdownOptions = [
    { label: "Remove Integration", onClick: handleSlackDisconnect },
    { label: "Configure", onClick: handleSlackConfigureClick },
  ];

  return (
    <div
      id="integrations-cards-container"
      style={{
        display: "flex",
        paddingBottom: "200px",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch",
      }}
    >
      <IntegrationCard
        logoSrc={JiraIntegrationLogo}
        headerText="Jira"
        captionText="Integrate with JIRA to import and sync your project's user stories"
        onButtonClick={handleJiraIntegration}
        isIntegrated={!!integrations.jira}
        buttonIconSrc={JiraIntegrationLogo}
        buttonText="Add Jira Integration"
        showCount={false}
        connectedChipDropdownOptions={jiraDropdownOptions}
      />
      <IntegrationCard
        logoSrc={SteamLogo}
        headerText="Steam"
        captionText="Connect your Steam account to analyze gaming habits and preferences"
        onButtonClick={handleSteamIntegration}
        isIntegrated={!!integrations.steam}
        buttonIconSrc={SteamLogo}
        buttonText="Add Steam Integration"
        showCount={false}
        connectedChipDropdownOptions={steamDropdownOptions}
        connectedChipText="Connected"
      />
      {/* <IntegrationCard
        logoSrc={SlackLogo}
        headerText="Slack"
        captionText="Integrate with Slack to connect your workspace and align with stakeholders"
        onButtonClick={handleSlackIntegration}
        isIntegrated={!!integrations.slack}
        buttonIconSrc={SlackLogo}
        buttonText="Add Slack Integration"
        showCount={false}
        connectedChipDropdownOptions={slackDropdownOptions}
        connectedChipText="Connected"
      /> */}
      <IntegrationCard
        logoSrc={DocIcon}
        headerText="Local Documents"
        captionText="Upload local files to automatically identify insights related to your initiatives"
        onButtonClick={handleLocalDocumentsUpload}
        isIntegrated={numLocalFilesUploaded > 0}
        buttonIconSrc={UploadFileIcon}
        buttonText="Upload Files"
        showCount={true}
        count={numLocalFilesUploaded}
        connectedChipText="View documents"
        onConnectedChipClick={handleLocalDocumentsChipClick}
      />
    </div>
  );
};

export default AllIntegrationsTabContent;
