import API from "./api";
import log from "../utils/logger";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { StatusContext } from "../contexts/StatusContext";

const SUPPORTED_FILE_TYPES = [
   // Video types
   "mp4",
   "mov",
   "avi",
   "wmv",
   "flv",
   "mkv",
   "webm",
   "3gp",
   "mpg",
   "mpeg",
   "m4v",
   // Audio types
   "mp3",
   "wav",
   "aac",
   "flac",
   "ogg",
   // Document types
   "txt",
   "doc",
   "docx",
   "pdf",
   "csv"
];

export function getSupportedFileTypes() {
   return SUPPORTED_FILE_TYPES.map((type) => `.${type}`);
}

export async function uploadFiles(files, setIsCurrentlyCheckingForUpdatesOnProcesses) {
   try {
      const validFiles = files.filter((file) => {
         const fileExtension = file.name.split(".").pop().toLowerCase();
         return SUPPORTED_FILE_TYPES.includes(fileExtension);
      });

      if (validFiles.length === 0) {
         throw new Error("No valid files to upload");
      }

      // Create processes for each file
      for (const file of validFiles) {
         const processId = `file_upload_${file.name.split(".")[0].replace(/\s+/g, "_")}`;
         await API.post("status-updates/create-new-process", {
            process_id: processId,
            title_to_display_to_user: `${file.name}`,
            status_messages: ["Starting upload"]
         });
      }

      // Set checking for updates to true
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);

      const uploadedFileMetadataObjects = await Promise.all(validFiles.map(async (file) => {
         const processId = `file_upload_${file.name.split(".")[0].replace(/\s+/g, "_")}`;

         // Transcode/descale files above 200 MB
         if (file.size > 200 * 1024 * 1024) {
            if (
               ["mp4", "mov", "avi", "wmv", "flv", "mkv", "webm", "3gp", "mpg", "mpeg", "m4v"].includes(
                  file.name.split(".").pop().toLowerCase()
               )
            ) {
               file = await transcodeVideo(file);
            } else if (["mp3", "wav", "aac", "flac", "ogg"].includes(file.name.split(".").pop().toLowerCase())) {
               file = await transcodeAudio(file);
            }
         }

         const response = await API.post("s3/get_presigned_url_for_upload", {
            file_extension: file.name.split(".").pop()
         });

         const presignedData = response.data;

         const fileExtension = presignedData.file_extension.toLowerCase();

         let videoFileExtension = "";
         let audioFileExtension = "";
         let textFileExtension = "";

         if (["mp4", "mov", "avi", "wmv", "flv", "mkv", "webm", "3gp", "mpg", "mpeg", "m4v"].includes(fileExtension)) {
            videoFileExtension = fileExtension;
            audioFileExtension = "m4a";
            textFileExtension = "txt";
         } else if (["mp3", "wav", "aac", "flac", "ogg"].includes(fileExtension)) {
            audioFileExtension = fileExtension;
            textFileExtension = "txt";
         } else if (["txt", "doc", "docx", "pdf", "csv"].includes(fileExtension)) {
            textFileExtension = fileExtension;
         }

         const fileMetadataResponse = await API.post("file-management/create-file-metadata", {
            file_name: presignedData.file_name,
            real_file_name: file.name,
            file_source: "computer_upload",
            original_file_extension: fileExtension,
            video_file_extension: videoFileExtension,
            audio_file_extension: audioFileExtension,
            text_file_extension: textFileExtension
         });

         const fileMetadataObject = fileMetadataResponse.data;

         await fetch(presignedData.presigned_url, {
            method: "POST",
            body: createFormData(presignedData.fields, file)
         }).catch((error) => {
            console.error("Error uploading file:", error);
         });

         await API.post("status-updates/add-status-message-to-process", {
            process_id: processId,
            new_message: "File uploaded"
         });

         if (["mp4", "mov", "avi", "wmv", "flv", "mkv", "webm", "3gp", "mpg", "mpeg", "m4v"].includes(fileExtension)) {
            const audioFile = await extractAudioFromVideo(file);
            const audioResponse = await API.post("s3/get_presigned_url_for_upload", {
               file_extension: "m4a",
               _id: presignedData.file_name
            });
            const audioPresignedData = audioResponse.data;

            // Upload file to S3
            await fetch(audioPresignedData.presigned_url, {
               method: "POST",
               body: createFormData(audioPresignedData.fields, audioFile, true)
            }).catch((error) => {
               console.error("Error uploading audio file:", error);
            });

            await API.post("status-updates/add-status-message-to-process", {
               process_id: processId,
               new_message: "Transcribing audio"
            });

            await API.post("local_file_uploads/transcribe-audio-and-add-txt-version-of-file-to-s3", {
               s3_key: audioPresignedData.s3_key,
               file_metadata_mongodb_doc_id: presignedData.file_name,
               process_id: processId
            });
         } else if (["mp3", "wav", "aac", "flac", "ogg"].includes(fileExtension)) {
            await API.post("status-updates/add-status-message-to-process", {
               process_id: processId,
               new_message: "Transcribing audio"
            });

            await API.post("local_file_uploads/transcribe-audio-and-add-txt-version-of-file-to-s3", {
               s3_key: presignedData.s3_key,
               file_metadata_mongodb_doc_id: presignedData.file_name,
               process_id: processId
            });
         } else {
            // For non-audio and non-video files (e.g., documents)
            console.log("Adding text version of file to 'every_user_uploaded_file_converted_to_txt' S3 folder");
            await API.post("local_file_uploads/add-txt-version-of-file-to-s3", {
               file_metadata_mongodb_doc_id: presignedData.file_name
            });
            
            // Add status message "Upload Complete"
            console.log(`calling add status message to process for processId: ${processId} and new_message: "Upload Complete"`);
            await API.post("status-updates/add-status-message-to-process", {
               process_id: processId,
               new_message: "Upload Complete"
            });

            // Set process as complete
            await API.post("status-updates/set-process-complete-and-add-completed-at-timestamp", {
               process_id: processId
            });
         }

         return fileMetadataObject;
      }));

      log(`All files uploaded successfully`);
      return uploadedFileMetadataObjects;
   } catch (error) {
      console.error(`Error uploading files:`, error);
      return [];
   }
}

function createFormData(fields, file, isUint8Array = false) {
   const formData = new FormData();

   for (const key in fields) {
      formData.append(key, fields[key]);
   }

   if (isUint8Array) {
      formData.append("file", new Blob([file], { type: "audio/mpeg" }), "audio.mp3");
   } else {
      formData.append("file", file, file.name);
   }

   return formData;
}

async function extractAudioFromVideo(videoFile) {
   const ffmpeg = new FFmpeg({ log: true });
   await ffmpeg.load();

   await ffmpeg.writeFile(videoFile.name, await fetchFile(videoFile));

   await ffmpeg.exec(["-i", videoFile.name, "-vn", "-c:a", "copy", "output.m4a"]);

   const audioFile = await ffmpeg.readFile("output.m4a");

   await ffmpeg.terminate();

   return audioFile;
}

async function transcodeVideo(videoFile) {
   const ffmpeg = new FFmpeg({ log: true });
   await ffmpeg.load();

   await ffmpeg.writeFile(videoFile.name, await fetchFile(videoFile));

   await ffmpeg.exec([
      "-i",
      videoFile.name,
      "-vf",
      "scale=1280:-1",
      "-c:v",
      "libx264",
      "-preset",
      "veryfast",
      "-crf",
      "28",
      "-c:a",
      "copy",
      "output.mp4"
   ]);

   const transcodedFile = await ffmpeg.readFile("output.mp4");

   await ffmpeg.terminate();

   return new File([transcodedFile], videoFile.name, { type: "video/mp4" });
}

async function transcodeAudio(audioFile) {
   const ffmpeg = new FFmpeg({ log: true });
   await ffmpeg.load();

   await ffmpeg.writeFile(audioFile.name, await fetchFile(audioFile));

   await ffmpeg.exec(["-i", audioFile.name, "-c:a", "aac", "-b:a", "128k", "output.m4a"]);

   const transcodedFile = await ffmpeg.readFile("output.m4a");

   await ffmpeg.terminate();

   return new File([transcodedFile], audioFile.name, { type: "audio/mp4" });
}

export function initiateFileUpload(setIsCurrentlyCheckingForUpdatesOnProcesses, navigate, onUploadComplete) {
   const fileInput = document.createElement("input");
   fileInput.type = "file";
   fileInput.multiple = true;
   fileInput.accept = getSupportedFileTypes().join(",");
   fileInput.onchange = async (event) => {
      const files = Array.from(event.target.files);
      if (files.length > 0) {
         const csvFiles = files.filter((file) =>
            file.name.toLowerCase().endsWith(".csv")
         );
         const otherFiles = files.filter((file) => {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            return (
               getSupportedFileTypes().includes(`.${fileExtension}`) &&
               fileExtension !== "csv"
            );
         });

         // Process CSV files if any
         if (csvFiles.length > 0) {
            navigate("/csv-viewer", { state: { csvFiles: csvFiles } });
         }

         // Upload other files if any
         if (otherFiles.length > 0) {
            try {
               const uploadedFileMetadataObjects = await uploadFiles(
                  otherFiles,
                  setIsCurrentlyCheckingForUpdatesOnProcesses
               );
               console.log("Upload completed:", uploadedFileMetadataObjects);
               // Call the onUploadComplete callback if provided
               if (onUploadComplete) {
                  onUploadComplete();
               }
            } catch (error) {
               console.error("Upload failed:", error);
            }
         }
      }
   };
   fileInput.click();
}